import React from "react";
import { ReactComponent as Bestseller } from "../img/imported_with_webpack/logo-cloud/bestseller.svg";
import { ReactComponent as Focus } from "../img/imported_with_webpack/logo-cloud/focus.svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Famous = ({ famousObject, className }) => {
  // {/* <div className={`flex flex-col items-center ${className}`}>
  //   <RenderMarkdown markdownContent={famousObject.title} />
  //   <ImageResponsive imageObject={famousObject.imageObject} />
  // </div> */}

  return (
    <div className="flex flex-wrap md:flex-nowrap w-full justify-evenly">
      <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-gray-800 grid grid-cols-4 gap-8 flex-row items-center justify-center">
        <a
          href="https://www.amazon.de/Meditation-loswerden-beruhigen-Meditationen-Audio-Download/dp/3442342775/"
          target="_blank"
          className="w-full"
        >
          <Bestseller />
        </a>
        <a
          href="https://www.penguinrandomhouse.de/Autor/Peter-Beer/p696455.rhd"
          target="_blank"
          className="w-full"
        >
          <img src="/logos/penguin.png" />
        </a>

        <a
          href="https://www.flowsummit.net/offer-2/"
          target="_blank"
          className="w-full"
        >
          <img src="/logos/flowsummit.png" className="rounded-lg" />
        </a>
        <a
          href="https://www.focus.de/gesundheit/ratgeber/gegen-corona-fuer-dich-der-grosse-zuhause-sonntag-innerer-frieden-und-gelassenheit-meditieren-sie-mit-peter-beer_id_12654257.html"
          target="_blank"
          className="w-full"
        >
          <Focus />
        </a>
      </div>
    </div>
  );
};

export default Famous;
